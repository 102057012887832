const JST_OFFSET_HOURS = 9;

export function filterFiveMinuteBarsByDaysBack(allBars, marketDaysBack) {
  let mostRecentBar = allBars[allBars.length - 1];
  let mostRecentDay = new Date(mostRecentBar[0]);

  // Set 6 AM as the cutoff for the day (24+6)
  if (mostRecentDay.getUTCHours() >= 6) {
    // Most recent day starts at 6
    mostRecentDay.setUTCHours(5, 59, 59, 0);
  } else {
    // most recent day starts yesterday at 6
    mostRecentDay.setUTCHours(-18, 59, 59, 0);
  }

  let totalDays = 1;

  for (var i = allBars.length - 1; i >= 0; --i) {
    let currentBarTimestamp = new Date(allBars[i][0]);

    // if the current time stamp is less than the start of the current
    // day, we need to increment the current day to the day of the
    // current timestamp
    if (
      currentBarTimestamp.getTime() <= mostRecentDay.getTime() &&
      totalDays < marketDaysBack
    ) {
      mostRecentDay = currentBarTimestamp;
      mostRecentDay.setUTCHours(5, 59, 59, 0);
      totalDays++;
    }
    if (totalDays >= marketDaysBack) {
      // Short circuit the rest of the run
      // because we have all the data we need
      break;
    }
  }

  return mostRecentDay;
}

export function getBarSizeForChartPeriod(type, count, realTimeChartEnabled) {
  if (realTimeChartEnabled) {
    // For real time chart, the type/count is also the bar size
    return `${count}${type}`;
  }

  if (type === 'day') {
    return count >= 5 ? '1hour' : '5min';
  } else if (type === 'month') {
    return '1day';
  } else if (type === 'year') {
    if (count <= 2) {
      return '1week';

      // 30 year bars get quarterly
      // 20 year bars get monthly
    } else if (count > 20) {
      return '1quarter';
    } else {
      return '1month';
    }
  }

  return 'null';
}

export function numberFormatter() {
  return function () {
    var formatNumber = function (value) {
      var magnitudes = [1000000000, 1000000];
      for (var i = 0; i < magnitudes.length; i++) {
        if (value < magnitudes[i]) {
          continue;
        }
        value /= magnitudes[i] * 1.0;
        switch (magnitudes[i]) {
          case 1000000000:
            return `${value}十億`;
          case 1000000:
            return `${value}百万`;
          default:
            return `${value}`;
        }
      }
      return value;
    };
    return formatNumber(this.value);
  };
}

export function getNDaysBackTimestamp(bars, daysBack) {
  if (bars.length === 0) {
    return;
  }

  const latestDate = bars.reduce((latest, bar) => Math.max(latest, bar[0]), 0);
  const result = new Date(latestDate);
  result.setDate(result.getDate() - daysBack);
  return result.getTime();
}

export function reduceBarsToTimePeriod(data, timePeriod, dailyBars) {
  let mostRecentBar = data.bars[data.bars.length - 1];
  let mostRecentDay = new Date(mostRecentBar[0]);
  // Get tonight's midnight as UTC time (24+9)
  mostRecentDay.setUTCHours(33, 0, 0, 0);

  switch (timePeriod.type) {
    case 'day':
      let closeOfMarket = closeOfMarketNdaysBack(
        dailyBars.bars,
        timePeriod.count
      );

      if (!closeOfMarket) {
        // if we don't have bar data for the last close, we just count days backwards
        // based on the latest bar and time period
        closeOfMarket = getNDaysBackTimestamp(data.bars, timePeriod.count);
      }
      closeOfMarket = new Date(closeOfMarket);
      closeOfMarket.setUTCHours(
        closeOfMarket.getUTCHours() + JST_OFFSET_HOURS,
        closeOfMarket.getUTCMinutes(),
        closeOfMarket.getUTCSeconds(),
        closeOfMarket.getUTCMilliseconds()
      );
      mostRecentDay = closeOfMarket;
      break;
    case 'month':
      mostRecentDay.setMonth(mostRecentDay.getMonth() - timePeriod.count);
      break;
    case 'year':
      mostRecentDay.setFullYear(mostRecentDay.getFullYear() - timePeriod.count);
      break;
    default:
      mostRecentDay.setDate(mostRecentDay.getDate() - timePeriod.count);
      break;
  }

  // Calculate the earliest timestamp we want bars for
  let earliestTime = mostRecentDay.getTime();

  // Use only bars with the isChartBar property set to true
  data.bars = data.bars.filter((bar) => {
    return bar[0] >= earliestTime && bar[5];
  });

  // Same for volume, filter bars with the isChartBar property set to true
  data.volume = data.volume.filter((bar) => {
    return bar[0] >= earliestTime && bar[2];
  });

  return data;
}

export function mapSyphonBarsToHighchartBars(rawBars, size) {
  let barData = [];
  let volumeData = [];

  rawBars.forEach((bar) => {
    let timestamp;

    if (size === '1day') {
      let timestampBits = bar.Timestamp.split('T');
      timestamp = new Date(timestampBits[0]).getTime();
    } else {
      timestamp =
        new Date(bar.Timestamp).getTime() + JST_OFFSET_HOURS * 60 * 60 * 1000;
    }

    // It was discovered that these EOD bars are still needed to calculate the indicators
    // in order to match the values we have for Sometaro. However, we need to exclude them
    // when displaying the chart, so they are still added but with a flag indicating this.
    let isChartBar = true;
    if (bar.Status === 29 && size === '5min') {
      // Filter out the EOD daily bar
      isChartBar = false;
    }

    barData.push([
      timestamp,
      bar.OpenPrice,
      bar.HighPrice,
      bar.LowPrice,
      bar.ClosePrice,
      isChartBar
    ]);

    volumeData.push([timestamp, bar.Volume, isChartBar]);
  });

  return {
    bars: barData,
    volume: volumeData
  };
}

export function timeStampsFromDailyBars(dailyBars) {
  return dailyBars
    .map((bar) => {
      return bar.Timestamp;
    })
    .sort((a, b) => {
      return new Date(a) - new Date(b);
    });
}

export function closeOfMarketNdaysBack(dailyBars, daysBack) {
  let timestamps = timeStampsFromDailyBars(dailyBars);
  return timestamps[timestamps.length - (daysBack + 1)];
}

// This function was added to see if we already load the bars data from Syphon but for this
// particular barSize we don't have data. This will indicate if we need to adjust the message
// we display to the user.
export const hasLoadedWithoutBarsData = (bars, symbol, barSize) => {
  if (
    !bars ||
    !symbol.toString() ||
    !bars[symbol.toString() + '/' + barSize] ||
    !!bars[symbol.toString() + '/' + barSize].isLoading
  ) {
    return false;
  }

  return !bars[symbol.toString() + '/' + barSize].data;
};

export const getBarInfo = (bars, symbol, barSize) => {
  return !!bars &&
    !!symbol.toString() &&
    !!bars[symbol.toString() + '/' + barSize]
    ? bars[symbol.toString() + '/' + barSize].data
    : null;
};

// This function gets the fullBarsData for the specified symbol
export const getSymbolFullBarsData = (bars, barSize, symbol) => {
  if (!bars) {
    return [];
  }

  const barInfo = getBarInfo(bars, symbol, barSize);

  if (!barInfo) {
    return [];
  }

  const result = mapSyphonBarsToHighchartBars(barInfo.bars, barSize);
  return result.bars ?? [];
};
